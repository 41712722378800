<template>
  <b-card
    no-body
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        <b-link
          :to="{ name: 'admin-family-view', params: { id: userData.user.family_id } }"
        >{{ userData.user.family_name }}
        </b-link>
      </h5>
    </b-card-header>

    <b-card-body>
      <ul class="list-unstyled my-1">
        <li
          v-for="item in parentsList"
          :key="item.id"
        >
          <span class="align-middle">
            {{ getParentLabel(item) }}:
            <b-link
              :to="{ name: 'admin-parent-profile', params: { id: item.user_parent_id } }"
            >{{ item.full_name }}</b-link>
          </span>
        </li>
      </ul>

      <span> Children:</span>
      <ul class="list mb-1">
        <li
          v-for="item in childrenList"
          :key="item.id"
        >
          <span class="align-middle">
            <b-link
              :to="{ name: 'admin-student-profile', params: { id: item.id } }"
            >{{ item.full_name }}</b-link>
          </span>
        </li>
      </ul>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { appParentsList } from '@/store/modules/app-parents/app-parenst-list'
import { appChildrenList } from '@/store/modules/app-children/app-children-list'

export default {
  directives: {
    Ripple,
  },
  components: {
    BLink,
    BCard,
    BCardHeader,
    BCardBody,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parentsList: [],
      childrenList: [],
    }
  },
  computed: {

  },
  created() {
    this.fetchParentsList()
    this.fetchChildrenList()
  },
  methods: {
    getParentLabel(user) {
      if (user.family_member_is_primary) {
        return 'Primary Parent'
      }

      return 'Secondary Parent'
    },
    async fetchParentsList() {
      const response = await appParentsList.actions.fetchParentsList({ familyId: this.userData.user.family_id })
      this.parentsList = response.filter(step => this.userData.user.family_member_id !== step.family_member_id)
    },
    async fetchChildrenList() {
      this.childrenList = await appChildrenList.actions.fetchChildrenList(this.userData.user.family_id)
    },
  },
}
</script>

<style>

</style>
